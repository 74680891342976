import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lomaprietamashup',
  templateUrl: './lomaprietamashup.component.html',
  styleUrls: ['./lomaprietamashup.component.css']
})
export class LomaprietamashupComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
