import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Shawn Clover';
}

class ImageComponent {
    offset = 100;
    defaultImage = 'https://www.placecage.com/1000/1000';
    images = `https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?fm=jpg 700w,
              https://images.unsplash.com/photo-1437818628339-19ded67ade8e?fm=jpg 1100w`;
}