import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-worldsfairtreasureisland',
  templateUrl: './worldsfairtreasureisland.component.html',
  styleUrls: ['./worldsfairtreasureisland.component.css']
  })
export class WorldsfairtreasureislandComponent implements OnInit {
    
    main = "https://photos.smugmug.com/photos/i-f5hZKkv/0/L/i-f5hZKkv-L.jpg";
    step1 = "https://photos.smugmug.com/photos/i-cP6k5MM/0/L/i-cP6k5MM-L.jpg";
    step2 = "https://photos.smugmug.com/photos/i-svQ6ss5/0/L/i-svQ6ss5-L.jpg";
    step3 = "https://photos.smugmug.com/photos/i-XPW2j3K/0/L/i-XPW2j3K-L.jpg";
    step4 = "https://photos.smugmug.com/photos/i-X8dn3W9/0/L/i-X8dn3W9-L.jpg";
    step5 = "https://photos.smugmug.com/photos/i-zGn36nd/0/L/i-zGn36nd-L.jpg";
    step6 = "https://photos.smugmug.com/photos/i-gZBvW7j/0/L/i-gZBvW7j-L.jpg";
    step7 = "https://photos.smugmug.com/photos/i-ZKp4kMB/0/L/i-ZKp4kMB-L.jpg";
    TIAdminBuilding = "https://photos.smugmug.com/photos/i-HM5gXK2/0/S/i-HM5gXK2-S.gif";
    TIHangars = "https://photos.smugmug.com/photos/i-vhHQQZw/0/S/i-vhHQQZw-S.jpg";
    TIBabies = "https://photos.smugmug.com/photos/i-4FcZJLR/0/S/i-4FcZJLR-S.jpg";
    TITower = "https://photos.smugmug.com/photos/i-LjDRbzR/0/S/i-LjDRbzR-S.jpg";
    TITower1 = "https://photos.smugmug.com/photos/i-4q4fNCs/0/S/i-4q4fNCs-S.jpg";
    TIWharf = "https://photos.smugmug.com/photos/i-SNhqLPM/0/S/i-SNhqLPM-S.jpg";
    TICoaster = "https://photos.smugmug.com/photos/i-xVPLV4d/0/S/i-xVPLV4d-S.jpg";

    url = location.href;


  constructor() { }

    ngOnInit() {  }
 }