import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bayshoreroundhouse',
  templateUrl: './bayshoreroundhouse.component.html',
  styleUrls: ['./bayshoreroundhouse.component.css']
})
export class BayshoreroundhouseComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
