import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transbay',
  templateUrl: './transbay.component.html',
  styleUrls: ['./transbay.component.css']
})
export class TransbayComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
