import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thenandnowmashups',
  templateUrl: './thenandnowmashups.component.html',
  styleUrls: ['./thenandnowmashups.component.css']
})
    export class ThenandnowmashupsComponent implements OnInit {
    fadeto1906 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-3zxP88X/1/571b6625/S/SF048-S.jpg";
    lomaprietamashup = "https://photos.smugmug.com/Cdn/Lomaprietamashup/n-RbmgRj/i-nZGV2Q7/0/6c667203/S/i-nZGV2Q7-S.jpg";

  constructor() { }

  ngOnInit() {
  }

}
