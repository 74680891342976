import { Component, OnInit } from '@angular/core';
//import { SmugmugService } from '../../smugmug.service';

@Component({
  selector: 'app-fadeto1906',
  templateUrl: './fadeto1906.component.html',
  styleUrls: ['./fadeto1906.component.css']
})
    export class Fadeto1906Component implements OnInit {
    //photos: any;
    //albumKey: string = 'dKgRT4';

    MA002 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-czZ6xtp/1/ecf4d0e2/L/MA002-L.jpg";
    SC001 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-HHWPMvp/1/cfd5c4c1/L/SC001-L.jpg";
    SF003 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-DznxHkv/1/dcec710e/L/SF003-L.jpg";
    SF017 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-3XKckBS/1/5bef5b7a/L/SF017-L.jpg";
    SF027 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-ZLBZJHF/1/b4f6520b/L/SF027-L.jpg";
    SF038 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-LrQS4Zr/1/83c8874c/L/SF042-L.jpg";
    SF044 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-ncTbLBk/1/c63f32c6/L/SF044-L.jpg";
    SF046 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-7NTcq5L/1/5c79bd75/L/SF046-L.jpg";
    SF048 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-3zxP88X/1/571b6625/L/SF048-L.jpg";
    SF062 = "https://photos.smugmug.com/Galleries/Fade-to-1906/i-ch7bskd/1/b913a081/L/SF062-L.jpg";
    SF064 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-xFrHWRT/1/492e15c7/L/SF064-L.jpg";
    SF074 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-hbCSgxG/1/8b26e8ec/L/SF074-L.jpg";
    SF091 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-L4m5Z9v/1/7a4b482a/L/SF091-L.jpg";
    SF095 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-hQBTRq9/1/2eda4ba3/L/SF095-L.jpg";
    SF098 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-qPjfxjw/1/916847f3/L/SF098-L.jpg";
    SF102 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-JdJb6CZ/1/2d0cb672/L/SF102-L.jpg";
    SF119 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-FrsvK7N/1/9b236a61/L/SF119-L.jpg";
    SF124 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-4KbtVzG/2/845ab938/L/SF124-L.jpg";
    SF130 = "https://photos.smugmug.com/Galleries/Fade-To-1906/i-xVkkBzT/1/bf3960ef/L/SF130-L.jpg";
    collage = "https://photos.smugmug.com/photos/i-CpqFPfD/0/f3d9afa1/X3/i-CpqFPfD-X3.jpg";
    cas = "https://photos.smugmug.com/photos/i-L2R3nkk/0/864a8487/L/i-L2R3nkk-L.jpg";
    me = "https://photos.smugmug.com/photos/i-jXC7j47/0/30584fb7/M/i-jXC7j47-M.jpg";

    url = location.href;
    constructor() { }

    ngOnInit() { }
    }
  /*
  constructor(private data: SmugmugService) { }

    ngOnInit() {
    this.data.getPhotos(this.albumKey).subscribe(photos => {
        this.photos = photos.Response.AlbumImage;
        //this.photos = Array.of(photos.Response.AlbumImage);
        //console.log(this.photos);
      }
    );
    }
    */


