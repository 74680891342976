import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drawbridge',
  templateUrl: './drawbridge.component.html',
  styleUrls: ['./drawbridge.component.css']
})
export class DrawbridgeComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
