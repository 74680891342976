import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creepyamusementpark',
  templateUrl: './creepyamusementpark.component.html',
  styleUrls: ['./creepyamusementpark.component.css']
})
export class CreepyamusementparkComponent implements OnInit {
    
    url = location.href;
    constructor() { }

  ngOnInit() {
  }

}
