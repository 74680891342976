import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abandoned',
  templateUrl: './abandoned.component.html',
  styleUrls: ['./abandoned.component.css']
})
  export class AbandonedComponent implements OnInit {
    fleishhacker = "https://photos.smugmug.com/Galleries/Abandoned/Fleishhacker/i-K9S2PdR/3/e533b61d/S/20120221-9908_09_10-Edit-S.jpg";
    drawbridge = "https://photos.smugmug.com/Galleries/Abandoned/Drawbridge/i-rcmCJP3/1/b45b5a08/S/20120128-9448_49_50_51_52_53_54-S.jpg";
    bayshoreroundhouse = "https://photos.smugmug.com/Galleries/Abandoned/Bayshore/i-fNx8ZQ2/1/e2468443/S/20111001-5495_6_7-Edit-S.jpg";
    grandi = "https://photos.smugmug.com/Galleries/Abandoned/Grandi/i-xNHV6hs/1/28262c71/S/20121229-9593_4_5-Edit-S.jpg";
    creepyamusementpark = "https://photos.smugmug.com/Galleries/Abandoned/Creepy-Amusements/i-8MBFsWR/1/e5641cca/S/IMG_0026-Edit-S.jpg";
    stairwaytoheaven = "https://photos.smugmug.com/photos/i-8b53xJw/1/2f26e086/S/i-8b53xJw-S.jpg";
    sixteenthstreetstation = "https://photos.smugmug.com/Galleries/Abandoned/16th-Street-Station/i-MSjwmTL/0/39f0dc1d/S/20120929-7550_1_2-Edit-Edit-S.jpg"


  constructor() { }

  ngOnInit() {
  }

}
