import { Component, OnInit } from '@angular/core';
import { Engine } from '../../../assets/js/engine.js';
import { symbols, sounds, InfoDisplay, EnemyBicycleBottom, EnemyCarBottom, EnemyTrainBottom, EnemyTrainTop, EnemyCarTop, EnemyBicycleTop, Player, PlayerSuccess, GameOver, NewGame } from '../../../assets/js/app.js';
import { _load } from '../../../assets/js/resources.js';

declare var symbols, sounds, InfoDisplay, EnemyBicycleBottom, EnemyCarBottom, EnemyTrainBottom, EnemyTrainTop, EnemyCarTop, EnemyBicycleTop, Player, PlayerSuccess, GameOver, NewGame:any;

@Component({
  selector: 'app-cluelesstexter',
  templateUrl: './cluelesstexter.component.html',
  styleUrls: ['./cluelesstexter.component.css']
})
export class CluelesstexterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
