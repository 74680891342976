import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mybay',
  templateUrl: './mybay.component.html',
  styleUrls: ['./mybay.component.css']
})
export class MybayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
