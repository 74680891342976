import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AirplanesComponent } from './airplanes/airplanes.component';
import { HomeComponent } from './home/home.component';
import { SmugmugComponent } from './smugmug/smugmug.component';
import { Post } from './post';
import { BlueprintsComponent } from './categories/blueprints/blueprints.component';
import { WorldsfairtreasureislandComponent } from './articles/worldsfairtreasureisland/worldsfairtreasureisland.component';
import { CandlestickgoodbyeComponent } from './articles/candlestickgoodbye/candlestickgoodbye.component';
import { MybayComponent } from './apps/mybay/mybay.component';
import { CluelesstexterComponent } from './apps/cluelesstexter/cluelesstexter.component';
import { ClockographComponent } from './apps/clockograph/clockograph.component';
import { MiniaturebayareaComponent } from './articles/miniaturebayarea/miniaturebayarea.component';
import { AlbumimagesComponent } from './albumimages/albumimages.component';
import { Fadeto1906Component } from './articles/fadeto1906/fadeto1906.component';
import { AboutComponent } from './pages/about/about.component';
import { PhotouseComponent } from './pages/photouse/photouse.component';
import { AbandonedComponent } from './categories/abandoned/abandoned.component';
import { DronographyComponent } from './categories/dronography/dronography.component';
import { RetroslidesComponent } from './categories/retroslides/retroslides.component';
import { FleishhackerComponent } from './articles/fleishhacker/fleishhacker.component';
import { DrawbridgeComponent } from './articles/drawbridge/drawbridge.component';
import { BayshoreroundhouseComponent } from './articles/bayshoreroundhouse/bayshoreroundhouse.component';
import { GrandiComponent } from './articles/grandi/grandi.component';
import { CreepyamusementparkComponent } from './articles/creepyamusementpark/creepyamusementpark.component';
import { VanishediconsComponent } from './categories/vanishedicons/vanishedicons.component';
import { TransbayComponent } from './articles/transbay/transbay.component';
import { StairwaytoheavenComponent } from './articles/stairwaytoheaven/stairwaytoheaven.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { OakknollnavalhospitalComponent } from './articles/oakknollnavalhospital/oakknollnavalhospital.component';
import { StanfordstadiumComponent } from './articles/stanfordstadium/stanfordstadium.component';
import { LomaprietamashupComponent } from './articles/lomaprietamashup/lomaprietamashup.component';
import { ThenandnowmashupsComponent } from './categories/thenandnowmashups/thenandnowmashups.component';
import { SixteenthstreetstationComponent } from './articles/sixteenthstreetstation/sixteenthstreetstation.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'airplanes', component: AirplanesComponent },
  { path: 'smugmug', component: SmugmugComponent },
  { path: 'blueprints', component: BlueprintsComponent},
  { path: 'worldsfairtreasureisland', component: WorldsfairtreasureislandComponent},
  { path: 'candlestickgoodbye', component: CandlestickgoodbyeComponent},
  { path: 'mybay', component: MybayComponent},
  { path: 'cluelesstexter', component: CluelesstexterComponent},
  { path: 'clockograph', component: ClockographComponent},
  { path: 'miniaturebayarea', component: MiniaturebayareaComponent},
  { path: 'albumimages', component: AlbumimagesComponent},
  { path: 'fadeto1906', component: Fadeto1906Component},
  { path: 'about', component: AboutComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent},
  { path: 'photouse', component: PhotouseComponent },
  { path: 'abandoned', component: AbandonedComponent},
  { path: 'vanishedicons', component: VanishediconsComponent},
  { path: 'dronography', component: DronographyComponent},
  { path: 'retroslides', component: RetroslidesComponent},
  { path: 'thenandnowmashups', component: ThenandnowmashupsComponent},
  { path: 'fleishhacker', component: FleishhackerComponent},
  { path: 'drawbridge', component: DrawbridgeComponent},
  { path: 'bayshoreroundhouse', component: BayshoreroundhouseComponent},
  { path: 'grandi', component: GrandiComponent},
  { path: 'creepyamusementpark', component: CreepyamusementparkComponent},
  { path: 'transbay', component: TransbayComponent},
  { path: 'stairwaytoheaven', component: StairwaytoheavenComponent},
  { path: 'oakknollnavalhospital', component: OakknollnavalhospitalComponent},
  { path: 'stanfordstadium', component: StanfordstadiumComponent},
  { path: 'lomaprietamashup', component: LomaprietamashupComponent},
  { path: 'sixteenthstreetstation', component: SixteenthstreetstationComponent},

  //redirects from old site
  { path: '2010/10/01/1906-2010-the-quake-blend-part-i', redirectTo: '/fadeto1906', pathMatch: 'full'},
  { path: '2012/08/17/1906-today-the-earthquake-blend-part-ii', redirectTo: '/fadeto1906', pathMatch: 'full'},
  { path: 'projects/fadeto1906', redirectTo: '/fadeto1906', pathMatch: 'full'},
  { path: '2014/10/01/1939-worlds-fair-on-treasure-island', redirectTo: '/worldsfairtreasureisland', pathMatch: 'full'},
  { path: '2014/12/18/candlestick-park-where-the-magic-happened', redirectTo: '/candlestickgoodbye', pathMatch: 'full'},
  { path: '2010/05/26/san-francisco-in-miniature', redirectTo: '/miniaturebayarea', pathMatch: 'full'},
  { path: '2012/01/31/welcome-to-drawbridge', redirectTo: '/drawbridge', pathMatch: 'full'},
  { path: '2012/02/23/inside-the-old-fleishhacker-poolhouse', redirectTo: '/fleishhacker', pathMatch: 'full'},
  { path: '2011/11/05/the-old-abandoned-bayshore-roundhouse', redirectTo: '/bayshoreroundhouse', pathMatch: 'full'},
  { path: '2012/12/30/inside-the-grandi', redirectTo: '/grandi', pathMatch: 'full'},
  { path: '2010/09/12/creepy-forgotten-northern-california-amusement-park', redirectTo: '/creepyamusementpark', pathMatch: 'full'},
  { path: '2010/08/02/so-long-transbay-terminal', redirectTo: '/transbay', pathMatch: 'full'},
  { path: '2010/08/20/stairway-to-heaven', redirectTo: '/stairwaytoheaven', pathMatch: 'full'},
  { path: '2014/10/08/the-loma-prieta-earthquake-1989-2014-mashup', redirectTo: '/lomaprietamashup', pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
