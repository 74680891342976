import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grandi',
  templateUrl: './grandi.component.html',
  styleUrls: ['./grandi.component.css']
})
export class GrandiComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
