import { Component, OnInit } from '@angular/core';
import { Airplane } from './airplane';
import { AIRPLANES } from './airplanes';

@Component({
  selector: 'app-airplanes',
  templateUrl: './airplanes.component.html',
  styleUrls: ['./airplanes.component.css']
})
export class AirplanesComponent implements OnInit {

  airplanes = AIRPLANES;

  constructor() { }

  ngOnInit() {
  }
  onSelect(airplane: Airplane): void {
  }
}
