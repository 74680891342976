import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oakknollnavalhospital',
  templateUrl: './oakknollnavalhospital.component.html',
  styleUrls: ['./oakknollnavalhospital.component.css']
})
export class OakknollnavalhospitalComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
