import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fleishhacker',
  templateUrl: './fleishhacker.component.html',
  styleUrls: ['./fleishhacker.component.css']
})
  export class FleishhackerComponent implements OnInit {

    photo01 = "https://photos.smugmug.com/photos/i-r6PDfxB/0/f24d6b66/S/i-r6PDfxB-S.jpg";
    photo02 = "https://photos.smugmug.com/photos/i-dZQNGFx/0/33efcb20/S/i-dZQNGFx-S.jpg";

    url = location.href;

  constructor() { }

  ngOnInit() { }

}
