import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vanishedicons',
  templateUrl: './vanishedicons.component.html',
  styleUrls: ['./vanishedicons.component.css']
})
    export class VanishediconsComponent implements OnInit {
    transbay = "https://photos.smugmug.com/Galleries/Abandoned/Transbay/i-F6nKhPb/1/2fb70db3/S/IMG_1577-edit-S.jpg";
    candlestickgoodbye = "https://photos.smugmug.com/photos/i-SHV26tJ/0/f8a89687/S/i-SHV26tJ-S.jpg";
    oakknollnavalhospital = "https://photos.smugmug.com/photos/i-DjKxx44/0/e20cd3ba/S/i-DjKxx44-S.jpg";
    stanfordstadium = "https://photos.smugmug.com/photos/i-MGPvfDT/0/06e59acb/S/i-MGPvfDT-S.jpg";

  constructor() { }

  ngOnInit() {
  }

}
