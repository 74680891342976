import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-smugmug',
  templateUrl: './smugmug.component.html',
  styleUrls: ['./smugmug.component.css']
  })
  

export class SmugmugComponent implements OnInit {
    results: any;

    constructor(private http: HttpClient) {

    }

    ngOnInit(): void {
        this.http.get<Response>('https://api.smugmug.com/api/v2/image/ntFS6zz-3?APIKey=X5CSVw8fbLPdZbsxccgPG97687bPcBSm&_accept=application%2Fjson')
            .subscribe(data => {
            //console.log("Name: " + data.name)
            this.results = data;
            })
    };

}

