import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  private data:any = []
  meta: any;
  constructor(private http: HttpClient, meta: Meta) { }

  ngOnInit() {
    this.meta.addTag( { name: "description", content:"About Shawn Clover" } );
  }


}
