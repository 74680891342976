import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private meta:Meta ){ }
//trying this for SEO but probably not necessary
  ngOnInit() {
    this.meta.addTag( { name: "Shawn Clover", content:"The 1906 earthquake and today photoblends, San Francisco history, urban exploration, photography, and vanished icons of the San Francisco Bay Area." } );
  }

}
