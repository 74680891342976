import { Injectable } from '@angular/core';
import { HttpClient} from  '@angular/common/http';
import { Observable } from 'rxjs'; //?
import { AlbumimagesComponent } from './albumimages/albumimages.component';

@Injectable({
  providedIn: 'root'
})

export class SmugmugService {
    API_URL = 'https://crossorigin.me/https://api.smugmug.com/api/v2';
    API_KEY = 'X5CSVw8fbLPdZbsxccgPG97687bPcBSm';
    constructor(private http: HttpClient) { }
    getPhotos(albumKey){
    return this.http.get<any>(`${this.API_URL}/album/${albumKey}!images?APIKey=${this.API_KEY}&_accept=application%2Fjson`);
    }
}