import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sixteenthstreetstation',
  templateUrl: './sixteenthstreetstation.component.html',
  styleUrls: ['./sixteenthstreetstation.component.css']
})
export class SixteenthstreetstationComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
