import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blueprints',
  templateUrl: './blueprints.component.html',
  styleUrls: ['./blueprints.component.css']
})
  export class BlueprintsComponent implements OnInit {
    worldsfairtreasureisland = "https://photos.smugmug.com/Cdn/n-cWDSkw/Worldsfairtreasureisland/i-f5hZKkv/0/b8e54c34/S/GoldenGateInternationalExposition-S.jpg";

  constructor() { }

  ngOnInit() {
  }

}
