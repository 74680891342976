import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stanfordstadium',
  templateUrl: './stanfordstadium.component.html',
  styleUrls: ['./stanfordstadium.component.css']
})
export class StanfordstadiumComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
