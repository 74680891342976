import { Component, OnInit } from '@angular/core';
import { SmugmugService } from '../smugmug.service';

@Component({
  selector: 'app-albumimages',
  templateUrl: './albumimages.component.html',
  styleUrls: ['./albumimages.component.css']
 })


export class AlbumimagesComponent implements OnInit {
    photos: any;
    albumKey: string = 'dKgRT4';
    
  constructor(private data: SmugmugService) { }

    ngOnInit() {
    this.data.getPhotos(this.albumKey).subscribe(photos => {
        this.photos = photos.Response.AlbumImage;
        //this.photos = Array.of(photos.Response.AlbumImage);
        //console.log(this.photos);
      }
    );
  }

}

