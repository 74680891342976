import { BrowserModule,Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DisqusModule } from 'ngx-disqus';

import { AirplanesComponent } from './airplanes/airplanes.component';
import { HomeComponent } from './home/home.component';
import { SmugmugComponent } from './smugmug/smugmug.component';
import { BlueprintsComponent } from './categories/blueprints/blueprints.component';
import { WorldsfairtreasureislandComponent } from './articles/worldsfairtreasureisland/worldsfairtreasureisland.component';
import { CandlestickgoodbyeComponent } from './articles/candlestickgoodbye/candlestickgoodbye.component';
import { MybayComponent } from './apps/mybay/mybay.component';
import { MiniaturebayareaComponent } from './articles/miniaturebayarea/miniaturebayarea.component';
import { CluelesstexterComponent } from './apps/cluelesstexter/cluelesstexter.component';
import { AlbumimagesComponent } from './albumimages/albumimages.component';
import { Fadeto1906Component } from './articles/fadeto1906/fadeto1906.component';
import { AboutComponent } from './pages/about/about.component';
import { PhotouseComponent } from './pages/photouse/photouse.component';
import { ClockographComponent } from './apps/clockograph/clockograph.component';
import { AbandonedComponent } from './categories/abandoned/abandoned.component';
import { DronographyComponent } from './categories/dronography/dronography.component';
import { RetroslidesComponent } from './categories/retroslides/retroslides.component';
import { FleishhackerComponent } from './articles/fleishhacker/fleishhacker.component';
import { DrawbridgeComponent } from './articles/drawbridge/drawbridge.component';
import { BayshoreroundhouseComponent } from './articles/bayshoreroundhouse/bayshoreroundhouse.component';
import { GrandiComponent } from './articles/grandi/grandi.component';
import { CreepyamusementparkComponent } from './articles/creepyamusementpark/creepyamusementpark.component';
import { VanishediconsComponent } from './categories/vanishedicons/vanishedicons.component';
import { TransbayComponent } from './articles/transbay/transbay.component';
import { StairwaytoheavenComponent } from './articles/stairwaytoheaven/stairwaytoheaven.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { OakknollnavalhospitalComponent } from './articles/oakknollnavalhospital/oakknollnavalhospital.component';
import { StanfordstadiumComponent } from './articles/stanfordstadium/stanfordstadium.component';
import { LomaprietamashupComponent } from './articles/lomaprietamashup/lomaprietamashup.component';
import { ThenandnowmashupsComponent } from './categories/thenandnowmashups/thenandnowmashups.component';
import { SixteenthstreetstationComponent } from './articles/sixteenthstreetstation/sixteenthstreetstation.component';

@NgModule({
  declarations: [
    AppComponent,
    AirplanesComponent,
    HomeComponent,
    SmugmugComponent,
    BlueprintsComponent,
    WorldsfairtreasureislandComponent,
    CandlestickgoodbyeComponent,
    MybayComponent,
    MiniaturebayareaComponent,
    CluelesstexterComponent,
    AlbumimagesComponent,
    Fadeto1906Component,
    AboutComponent,
    PhotouseComponent,
    ClockographComponent,
    AbandonedComponent,
    DronographyComponent,
    RetroslidesComponent,
    FleishhackerComponent,
    DrawbridgeComponent,
    BayshoreroundhouseComponent,
    GrandiComponent,
    CreepyamusementparkComponent,
    VanishediconsComponent,
    TransbayComponent,
    StairwaytoheavenComponent,
    PrivacypolicyComponent,
    OakknollnavalhospitalComponent,
    StanfordstadiumComponent,
    LomaprietamashupComponent,
    ThenandnowmashupsComponent,
    SixteenthstreetstationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LazyLoadImageModule,
    DisqusModule.forRoot('shawnclover')
  ],
  providers: [ Meta ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
