import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candlestickgoodbye',
  templateUrl: './candlestickgoodbye.component.html',
  styleUrls: ['./candlestickgoodbye.component.css']
})
export class CandlestickgoodbyeComponent implements OnInit {

  url = location.href;
  constructor() { }

  ngOnInit() {
  }

}
