import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retroslides',
  templateUrl: './retroslides.component.html',
  styleUrls: ['./retroslides.component.css']
})
export class RetroslidesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
